import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Vault = lazy(() => import('./pages/Vault'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Settings = lazy(() => import('./pages/Settings'));

function App() {
  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={3000} />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Vault />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/change-password" element={<Settings />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
